export default {
    data() {
        return {
            educations: [ {
                school: "Netaji Subhash Engineering College",
                location: "Kolkata",
                duration: "August 2016 - August 2020",
                course: " Bachelor of Technology in Information Technology"
            },
                {
                    school: "Burnpur Boys' High School",
                    location: "Burnpur, Asansol",
                    duration: "January 2013 - March 2015",
                    course: "Science ( Physics, Chemistry, Math)"
                }


            ]
        }
    }
}