export default {
    data() {
        return {
            skills: [
                "Java",
                "Python",
                "SQL",
                "HTML",
                "JavaScript",
                "Git",
                "Docker",
                "Kubernetes",
                "Spring",
                "Spring Boot",
                "JUnit 5",
                "Mockito",
                "REST API",
                "Apache Kafka",
                "Elasticsearch",
                "Microservices",
                "GitHub Copilot",
                "IntelliJ IDEA",
                "Eclipse IDE",
                "Visual Studio Code",
                "Maven",
                "Jenkins",
                "Docker Desktop",
                "Podman",
                "Minikube",
                "Postman",
                "Swagger UI",
                "MySQL Workbench",
                "Oracle SQL Developer",
                "SonarLint",
                "SonarQube"
            ],
        };
    },
}