<template>
    <footer class="footer">
        <div class="footer-content">
            <p>&copy; 2024 Rahul Ojha.</p>
            <ul class="footer-links">
                <li><a href="#home" class="smooth-link">Home</a></li>
                <li><a href="#about" class="smooth-link">About</a></li>
                <li><a href="#projects" class="smooth-link">Projects</a></li>
                <li><a href="#education" class="smooth-link">Education</a></li>
                <li><a href="#contact" class="smooth-link">Contact</a></li>
            </ul>
            <div class="social-links">
                <a href="https://github.com/rahul-ojha-07" target="_blank"><i class="fab fa-github"></i></a>
                <a href="https://www.linkedin.com/in/rahulojha07" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                <a href="https://x.com/rahul_ojha_07" target="_blank"><i class="fab fa-x-twitter"></i></a>
                <a href="https://www.facebook.com/rahul.ojha.07" target="_blank"><i class="fab fa-facebook"></i></a>
                <a href="https://medium.com/@rahul-ojha-07" target="_blank"><i class="fab fa-medium"></i></a>
                <a href="https://stackoverflow.com/users/8400785/rahul-ojha-07" target="_blank"><i
                        class="fab fa-stack-overflow"></i></a>
                <a href="https://www.instagram.com/rahul_ojha_07/" target="_blank"><i class="fab fa-instagram"></i></a>
            </div>
<!--          <p> Favicon by <a href="https://www.flaticon.com/free-icons/coding" title="coding icons"> Erix - Flaticon</a></p>-->
<!--          <a href="https://www.flaticon.com/free-icons/developer" title="developer icons">Developer icons created by Design Circle - Flaticon</a>-->
        </div>
    </footer>
  </template>
  
  <script src="./FooterComponent.js"></script>
  
  <style src="./FooterComponent.scss" lang="scss"></style>
  