export default {
    data() {
        return {
            projects: [
                {
                    title: "OpenAi Demo",
                    description: "This Java application serves as a demonstration of how to effectively use the OpenAI API. It provides users with a clear example of integrating and utilizing the API for various tasks.",
                    skills: ["java","spring-boot","openai","chatgpt","chatgpt-api","openi-api"],
                    image: "https://opengraph.githubassets.com/22ff888d01eb61a25b7fc160eb3087f778ebdb7f29dfb6fcc74190b36a710c50/rahul-ojha-07/openai-demo", // Adjust image paths as needed
                    github: "https://github.com/rahul-ojha-07/openai-demo",
                    // liveDemo: "https://your-deployment-link.com"
                },
                {
                    title: "Excel To Json",
                    description: "This web application allows users to upload a single-sheet Microsoft Excel file and convert it into structured JSON data. It simplifies data transformation, making it accessible for developers and data enthusiasts alike.",
                    skills: ["Java", "JSON", "GSON", "apache-poi", "Servlets"],
                    image: "https://opengraph.githubassets.com/22ff888d01eb61a25b7fc160eb3087f778ebdb7f29dfb6fcc74190b36a710c50/rahul-ojha-07/excel-to-json",
                    github: "https://github.com/rahul-ojha-07/excel-to-json",
                    // liveDemo: "https://your-deployment-link.com"
                },
                {
                    title: "Weather Bot Telegram",
                    description: "This Telegram weather bot provides users with real-time weather updates for their current location and allows them to search for weather information in other locations. It offers a convenient way to stay informed about weather conditions.",
                    skills: ["Java", "telegram-api", "telegram-bot","spring-boot", "openweathermap-api"],
                    image: "https://opengraph.githubassets.com/22ff888d01eb61a25b7fc160eb3087f778ebdb7f29dfb6fcc74190b36a710c50/rahul-ojha-07/weather-bot-telegram/",
                    github: "https://github.com/rahul-ojha-07/weather-bot-telegram",
                    // liveDemo: "https://your-deployment-link.com"
                },
                {
                    title: "Weather App",
                    description: "This Weather App, built with Node.js and Express.js, serves as a practical learning tool for understanding REST API concepts and the fundamentals of Node.js. It provides hands-on experience in developing web applications using these technologies.",
                    skills: ["Node.js", "Express.js", "OpenWeatherApi"],
                    image: "https://opengraph.githubassets.com/22ff888d01eb61a25b7fc160eb3087f778ebdb7f29dfb6fcc74190b36a710c50/rahul-ojha-07/Weather-App-NodeJs", // Adjust image paths as needed
                    github: "https://github.com/rahul-ojha-07/Weather-App-NodeJs",
                    // liveDemo: "https://your-deployment-link.com"
                },
                {
                    title: "Flappy Bird",
                    description: "This Flappy Bird game, created using Java Swing, offers an engaging way to learn about game development and graphical user interface design in Java. It showcases the fundamentals of game mechanics and event handling.",
                    skills: ["Java", "Swing", "Maven"],
                    image: "https://opengraph.githubassets.com/22ff888d01eb61a25b7fc160eb3087f778ebdb7f29dfb6fcc74190b36a710c50/rahul-ojha-07/flappy-bird", // Adjust image paths as needed
                    github: "https://github.com/rahul-ojha-07/flappy-bird",
                    // liveDemo: "https://your-deployment-link.com"
                },{
                    title: "Springboot Kafka Kubernetes",
                    description: "This project demonstrates how to deploy Kafka and Spring Boot applications on Kubernetes. It provides a practical guide for leveraging container orchestration to manage and scale applications effectively.",
                    skills: ["Java", "Spring Boot", "Docker", "Kubernetes", "Kafka"],
                    image: "https://opengraph.githubassets.com/22ff888d01eb61a25b7fc160eb3087f778ebdb7f29dfb6fcc74190b36a710c50/rahul-ojha-07/springboot-kafka-k8s", // Adjust image paths as needed
                    github: "https://github.com/rahul-ojha-07/springboot-kafka-k8s",
                    // liveDemo: "https://your-deployment-link.com"
                }
                // Add more projects as needed
            ]
        };
    }
};